import React, { useState, useEffect } from 'react'
import './dashboard.css'
import axios from 'axios'
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from 'recharts'

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({})

  // const api_url = 'https://api.networkon.io/super/dailyReport'
  const api_url = 'https://api.networkon.io/super/dailyReport'

  var today = new Date()
  var dd = String(today.getDate()).padStart(2, '0')
  var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
  var yyyy = today.getFullYear()
  today = yyyy + '-' + mm + '-' + dd

  async function getData() {
    const params = {
      date: today,
    }
    const response = await axios.get(api_url, {
      params: params,
    })
    if (response && response.data && response.data.data) {
      setDashboardData(response.data.data)
    }
    // console.log(response.data.data)
    console.log(today)
  }

  useEffect(() => {
    getData()

    let frequency = 300000
    setInterval(getData, frequency)
    return () => {}
  }, [])

  let days_count = [
    {
      day: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[0].day
        : '2021 - 11 - 7',
      count: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[0].count
        : 0,
    },
    {
      day: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[1].day
        : '2021 - 11 - 8',
      count: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[1].count
        : 0,
    },
    {
      day: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[2].day
        : '2021 - 11 - 9',
      count: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[2].count
        : 0,
    },
    {
      day: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[3].day
        : '2021 - 11 - 10',
      count: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[3].count
        : 0,
    },
    {
      day: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[4].day
        : '2021 - 11 - 11',
      count: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[4].count
        : 0,
    },
    {
      day: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[5].day
        : '2021 - 11 - 12',
      count: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[5].count
        : 0,
    },
    {
      day: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[6].day
        : '2021 - 11 - 13',
      count: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[6].count
        : 0,
    },
    {
      day: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[7].day
        : '2021 - 11 - 14',
      count: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[7].count
        : 0,
    },
    {
      day: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[8].day
        : '2021 - 11 - 15',
      count: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[8].count
        : 0,
    },
    {
      day: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[9].day
        : '2021 - 11 - 16',
      count: dashboardData.Job_acc_to_days
        ? dashboardData.Job_acc_to_days[9].count
        : 0,
    },
  ]

  console.log(dashboardData.Active_users)

  return (
    <>
      <div className='row'>
        <div className='col s12 m6'>
          <div className='card #1a237e indigo darken-4'>
            <div className='card-content white-text'>
              <span className='card-title'>Users</span>
              <h4 className='center'>{dashboardData.users || 0}</h4>
            </div>
          </div>
        </div>
        <div className='col s12 m6'>
          <div className='card #1a237e indigo darken-4'>
            <div className='card-content white-text'>
              <span className='card-title'>Supervisors</span>
              <h4 className='center'>{dashboardData.supervisors || 0}</h4>
            </div>
          </div>
        </div>
        <div className='col s12 m6'>
          <div className='card #1a237e indigo darken-4'>
            <div className='card-content white-text'>
              <span className='card-title'>Resources</span>
              <h4 className='center'>{dashboardData.resources || 0}</h4>
            </div>
          </div>
        </div>
        <div className='col s12 m6'>
          <div className='card #1a237e indigo darken-4'>
            <div className='card-content white-text'>
              <span className='card-title'>Jobs</span>
              <h4 className='center'>{dashboardData.jobs || 0}</h4>
            </div>
          </div>
        </div>
        <div className='col s12 m6'>
          <div className='card'>
            <div className='white-text'>
              <ResponsiveContainer width='100%' aspect={2.1}>
                <LineChart
                  data={days_count}
                  width={500}
                  height={1000}
                  margin={{ top: 20, right: 50, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <Tooltip />
                  <Line
                    dot={{ stroke: '#1c40c5', strokeWidth: 3 }}
                    dataKey='count'
                    stroke='#1c40c5'
                    activeDot={{ r: 8 }}
                  ></Line>
                  <XAxis dataKey='day' />
                  <YAxis />
                  {/* <Legend /> */}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <div className='col s12 m6'>
          <div className='card'>
            <div className='my-class'>
              {/* TABLE */}

              {dashboardData.Active_users && (
                <table className='highlight bordered centered responsive-table'>
                  <thead>
                    <tr>
                      <th>User ID</th>
                      <th>Name</th>
                    </tr>
                  </thead>

                  <tbody>
                    {dashboardData.Active_users ? (
                      dashboardData.Active_users.map((user) => (
                        <tr>
                          <td>{user.user_id}</td>
                          <td>{user.name}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>123</td>
                        <td>abc</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
